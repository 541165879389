import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import PhoneInput from '../PhoneInput';
import { QUERIES } from '../../constants/constants';
import {
  otpVerifyApi,
  smsLoginApi,
} from '../../services/auth-service';
import LoginUsingEmail from './LoginUsingEmail';
import SignUp from './SignUp';
import { useAppDispatch } from '../../store/hook';
import { setUserData } from '../../feature/auth/authSlice';
import LegalLinksWrapper from '../LegalLinksWrapper';
import TextableDivider from '../TextableDivider';
import Spacer from '../Spacer/Spacer';
import { AlertCircle, X } from 'react-feather';
import BottomModal from '../Modal/BottomModal';
import CountdownTimer from '../CountdownTimer';

const cookies = new Cookies();

let currentOtpIndex = 0;
const LoginUsingPhoneNumber = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneValidationMessage, setPhoneValidationMessage] =
    useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpValidationMessage, setOtpValidationMessage] =
    useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);

  const inputRef: any = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  const handleOnChange = (event: any, index: any) => {
    const value = event?.target.value;
    if (isNaN(value)) return false;

    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
    }

    setOtp(newOtp);
  };

  const handleOnKeyDown = (event: any, index: any) => {
    currentOtpIndex = index;

    if (event.key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowLeft') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowRight') {
      setActiveOtpIndex(currentOtpIndex + 1);
    }
  };

  const validatePhoneNumber = function () {
    const phoneRegex = new RegExp('^[6-9][0-9]{9}');
    if (!phoneRegex.test(phoneNumber) || phoneNumber === '') {
      setPhoneValidationMessage('Enter your phone number');
    } else {
      setPhoneValidationMessage('');
    }
  };

  const signIn = async () => {
    const payload = {
      phoneNumber: phoneNumber,
    };

    try {
      const response = await smsLoginApi(payload);

      // ERR: handle if status is failed (if service is down or internet is not working)
      if (response.data.success) {
        setOtpSent(true);
        setShowOtp(true);
        history.pushState(null, '', '/otp-verify');
        // TODO : change url path to verify-otp for back button compatibility
      }
    } catch (error: any) {
      console.log(
        '🚀 ~ file: LoginUsingPhoneNumber.tsx:118 ~ signIn ~ error:',
        error
      );
      // ERROR : problem
      // setErrorMessage(error.response.data.message);
      setErrorMessage(
        error.code === 'ERR_NETWORK'
          ? 'Something went wrong'
          : error?.response?.data?.message
      );
      setShowOtp(false);

      if (error?.response?.status === 404) {
        setShowSignUp(true);
      }
      // setErrorMessage('User not registered.');
      // if (!error.response.data.success) {
      //   navigate(`/signup}`);
      // }
    }
  };

  const otpVerify = async () => {
    const payload = {
      phoneNumber: phoneNumber,
      otp: otp.join(''), // ERR: if otp value is less than 6 digit don't send req
    };

    console.log(payload);

    try {
      const response = await otpVerifyApi(payload);

      if (response.data.success) {
        cookies.set('token', response?.data?.data.token, {
          path: '/',
          expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          // httpOnly: true,
          // secure: true,
        });

        // If Otp matches then
        if (response.data.data.passwordEnabled) {
          setShowEmailForm(true);
        } else {
          dispatch(setUserData(response.data.data));
          navigate('/');
        }
      }
    } catch (error: any) {
      setOtpValidationMessage(
        error.code === 'ERR_NETWORK'
          ? 'Something went wrong'
          : error?.response?.data?.message
      );
    }
  };

  const handleSendOtp = (event: any) => {
    event.preventDefault();
    validatePhoneNumber();

    if (!phoneValidationMessage && phoneNumber) {
      console.log('user is signed in');
      signIn();
    }
  };

  const handleResendOTP = () => {
    validatePhoneNumber();

    if (!phoneValidationMessage && phoneNumber) {
      console.log('user is signed in');
      signIn();
    }
  };

  const handleVerifyOtp = (event: any) => {
    event.preventDefault();
    console.log(otp.join(''));
    otpVerify();
  };

  const handleTimeOver = () => {
    setOtpSent(false);
  };

  return (
    <>
      {!showSignUp ? (
        <>
          {!showEmailForm ? (
            <LoginWrapper>
              <LoginText>Login</LoginText>
              <SubTitle>Enter your phone number to continue</SubTitle>

              <AuthForm>
                <PhoneInput
                  errorMsg={phoneValidationMessage}
                  required={true}
                  label={
                    phoneValidationMessage
                      ? phoneValidationMessage
                      : 'Phone number'
                  }
                  type="tel"
                  value={phoneNumber}
                  onChange={(event: any) => {
                    if (isNaN(event.target.value)) return false;
                    setPhoneValidationMessage('');
                    setPhoneNumber(event.target.value);
                  }}
                  onBlur={() => {
                    if (phoneNumber) {
                      validatePhoneNumber();
                    }
                  }}
                  autoComplete="on"
                  autoFocus={true}
                  maxLength={10}
                />

                {/* TODO: set tranform and opacity to animate otp fields */}
                {showOtp && (
                  <BottomModal
                    // handleDismiss={() => setShowOtp(false)}
                    maxWidth="440px"
                  >
                    <Header>
                      <HeadingText>OTP Verification</HeadingText>
                      <CancelButton
                        onClick={() => {
                          setShowOtp(false);
                        }}
                      >
                        <X />
                      </CancelButton>
                    </Header>
                    <OTPVerifyWrapper>
                      <Content>
                        <OTPText>
                          Check text messages for your OTP
                        </OTPText>

                        <OtpWrapper>
                          {/* // TODO : style Error message  */}
                          {errorMessage && (
                            <>
                              <LoginErrorMessage>
                                <ErrorIcon>
                                  <AlertCircle
                                    size={18}
                                    color="#d40a0a"
                                  />
                                </ErrorIcon>
                                <Text>{errorMessage}</Text>
                              </LoginErrorMessage>
                            </>
                          )}

                          <OtpInnerWrapper>
                            <InputsWrapper>
                              {otp.map((_: any, index: any) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Input
                                      ref={
                                        index === activeOtpIndex
                                          ? inputRef
                                          : null
                                      }
                                      autoFocus={
                                        index === activeOtpIndex
                                          ? inputRef
                                          : null
                                      }
                                      type="number"
                                      inputMode="numeric"
                                      maxLength={1}
                                      pattern="[a-zA-Z0-9]{1}"
                                      value={otp[index]}
                                      onChange={(event) =>
                                        handleOnChange(event, index)
                                      }
                                      onKeyDown={(event) =>
                                        handleOnKeyDown(event, index)
                                      }
                                      onFocus={(e) =>
                                        e.target.select()
                                      }
                                    />
                                  </React.Fragment>
                                );
                              })}
                            </InputsWrapper>
                          </OtpInnerWrapper>
                        </OtpWrapper>
                      </Content>
                      {otpValidationMessage && (
                        <>
                          <OTPLoginErrorMessage>
                            <ErrorIcon>
                              <AlertCircle
                                size={18}
                                color="#d40a0a"
                              />
                            </ErrorIcon>
                            <Text>{otpValidationMessage}</Text>
                          </OTPLoginErrorMessage>
                          <Spacer y={16} />
                        </>
                      )}
                      <ButtonsWrapper>
                        <CtaButton
                          onClick={(e) => {
                            handleVerifyOtp(e);
                          }}
                        >
                          VERIFY OTP
                        </CtaButton>
                      </ButtonsWrapper>
                      <CountdownTimer
                        initialTime={60}
                        onTimeOver={handleTimeOver}
                        handleAction={handleResendOTP}
                      />
                      {/* // TODO : show Error message */}
                      {/* TODO: dispatch an action to show location modal */}
                    </OTPVerifyWrapper>
                  </BottomModal>
                )}

                {errorMessage && (
                  <>
                    <LoginErrorMessage>
                      <ErrorIcon>
                        <AlertCircle size={18} color="#d40a0a" />
                      </ErrorIcon>
                      <Text>{errorMessage}</Text>
                    </LoginErrorMessage>
                  </>
                )}

                <SubmitButton onClick={handleSendOtp}>
                  {/* {showOtp ? 'VERIFY OTP' : 'LOGIN'} */}
                  LOGIN
                </SubmitButton>

                {/* TODO: create a component LegalLinksWrapper instead of div */}
                <LegalLinksWrapper
                  text="clicking on Login"
                  termsUrl="https://ymiieat.com/terms"
                  privacyUrl="https://ymiieat.com/privacy"
                  openInNewTab={true}
                />

                <Spacer y={20} />

                <TextableDivider text="or" margin="0 10px" />

                <SignUpLinkWrapper>
                  <SignUpLink to="/signup">
                    CREATE AN ACCOUNT
                  </SignUpLink>
                </SignUpLinkWrapper>

                {/* <div
                    style={{ marginTop: '3rem' }}
                    onClick={() => setShowOtp(true)}
                    >
                      {' '}
                      show
                    </div> */}

                {/* <LinkWrapper>
                      <AnchorLink to="/signup">Sign Up</AnchorLink>
                    </LinkWrapper> */}
              </AuthForm>
            </LoginWrapper>
          ) : (
            <LoginUsingEmail></LoginUsingEmail>
          )}
        </>
      ) : (
        <SignUp mobileNumber={phoneNumber} />
      )}
    </>
  );
};

const LoginWrapper = styled.div``;

const LoginText = styled.h4`
  font-size: 20px;
  margin: 2px 0 8px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  margin: 0%;
  color: #707579;
  line-height: 1.3125;
  text-align: center;
`;

const ErrorIcon = styled.div`
  margin-right: 6px;
  width: 18px;
  height: 18px;
`;

const Text = styled.div``;

const LoginErrorMessage = styled(SubTitle)`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 16px;
  margin-left: 20px;
  color: #d40a0a;
  font-size: 16px;
  font-weight: 600;
`;

const OTPLoginErrorMessage = styled(LoginErrorMessage)`
  margin-top: -16px;
`;

const SignUpLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: white;
  width: 100%;
  height: 100%;
  letter-spacing: 0.3px;

  &:hover {
    color: #cfcfcf;
  }
`;

const AuthForm = styled.form`
  /* flex: 0; */
  height: 100%;
  width: 100%;
  margin-top: 40px;
  padding: 0px 16px;

  @media ${QUERIES.tabletAndUp} {
    padding: 0;
  }
`;

const OtpWrapper = styled.div`
  text-align: center;
  font-size: 20px;
  margin-top: 16px;
  padding: 7px 6px;
`;

const OtpInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 370px;
`;

const Input = styled.input`
  height: 48px;
  /* width: 48px; */
  width: 15%;
  padding: 0px;
  text-align: center;
  font-size: 19px;
  font-weight: 700;
  color: rgb(28, 28, 28);
  border: 2px solid rgb(207, 207, 207);
  border-radius: 14px;
  appearance: none;
  outline: none;
  appearance: none;
  outline: none;
  transition: all 250ms;
  border: ${(prop: any) => prop.value && '2px solid #161616'};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    border: 2px solid #077507;
  }
`;

const Button = styled.button`
  margin-top: 24px;
  border-radius: 14px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  text-align: center;
  color: black;
  background-color: var(--color-cta);
  letter-spacing: 0.3px;
`;

const SubmitButton = styled(Button)``;

const SignUpLinkWrapper = styled.div`
  margin-top: 24px;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  text-align: center;
  color: black;
  background-color: #38444d;
  /* --shadow-color: 145deg 100% 12%;
box-shadow: 0px 0px 4px -2px hsl(var(--shadow-color)),
  0px 0px 4px -2px hsl(var(--shadow-color)); */
`;

// Modal styling

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* max-width: 370px; */
`;

const HeadingText = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 6px;
  padding-left: 4px;
  letter-spacing: 0.3px;

  @media ${QUERIES.tabletAndUp} {
    font-size: 22px;
  }
`;

const Content = styled.div`
  margin-top: 32px;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 24px;
  border-radius: 12px;
`;

const OTPText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 16px;
  color: #413e3e;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;

  @media ${QUERIES.tabletAndUp} {
    font-size: 15px;
    gap: 20px;
  }
`;

const ModalButton = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid var(--color-secondary);
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 2px -2px hsl(var(--shadow-color));

  &:hover {
    box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
      0px 0px 4px -2px hsl(var(--shadow-color));
  }

  @media ${QUERIES.tabletAndUp} {
    height: 50px;
  }
`;

const CtaButton = styled(ModalButton)`
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: black;
  background-color: var(--color-cta);

  --shadow-color: 0deg 100% 38.35%;
  box-shadow: 0px 2px 2px -2px hsl(var(--shadow-color));

  &:hover {
    box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
      0px 0px 4px -2px hsl(var(--shadow-color));
  }
`;

const CancelButton = styled(ModalButton)`
  width: fit-content;
  height: fit-content;
`;

const OTPVerifyWrapper = styled.div`
  margin: 0 auto;
  max-width: 370px;
`;

export default LoginUsingPhoneNumber;
