import React from 'react';
import styled from 'styled-components';

const TextableDivider = ({ text, margin }: any) => {
  return (
    <Wrapper margin={margin}>
      <DividerLine />
      <TextContainer>{text}</TextContainer>
      <DividerLine />
    </Wrapper>
  );
};
const Wrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props: any) => (props.margin ? props.margin : 0)};
`;

const DividerLine = styled.hr`
  flex: 1;
  border-top: 1px solid #000;
`;

const TextContainer = styled.div`
  margin: 0 10px;
`;

export default TextableDivider;
