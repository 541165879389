import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CountdownTimer = ({
  initialTime = 60,
  onTimeOver,
  handleAction,
}: any) => {
  const [time, setTime] = useState(initialTime);
  const [timerRunning, setTimerRunning] = useState(true);

  useEffect(() => {
    let timer: any;
    if (timerRunning && time > 0) {
      timer = setTimeout(() => setTime(time - 1), 1000);
    } else if (time === 0) {
      setTimerRunning(false);
      onTimeOver && onTimeOver();
    }

    return () => clearTimeout(timer);
  }, [time, timerRunning, onTimeOver]);

  const handleResend = () => {
    setTime(initialTime);
    setTimerRunning(true);
    handleAction();
  };

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <ResendOTPWrapper>
      <Timer>{formatTime(time)}</Timer>
      {/* <CountdownTimerWrapper>
          <CountdownTimer
            initialTime={60}
            onTimeOver={handleTimeOver}
          />
        </CountdownTimerWrapper> */}

      <ResendOtpCTAWrapper>
        Not received OTP?
        <ResendOTPButton
          onClick={handleResend}
          // disabled={otpSent}
          disabled={timerRunning}
        >
          Resend Now
        </ResendOTPButton>
      </ResendOtpCTAWrapper>
    </ResendOTPWrapper>
  );
};
const ResendOTPWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Timer = styled.div`
  font-size: 32px;
`;

const CountdownTimerWrapper = styled.div``;

const ResendOtpCTAWrapper = styled.div``;

const ResendOTPButton = styled.button`
  display: inline-block;
  text-decoration: none;
  color: var(--color-primary);
  margin-left: 8px;
`;

export default CountdownTimer;
