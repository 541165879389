import React from 'react';
import { useNavigate } from 'react-router-dom';

const Notfound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <button onClick={() => navigate('/')}>Go Back</button>
      </div>
      Notfound
    </div>
  );
};

export default Notfound;
