import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LegalLinksWrapper = ({
  text,
  termsUrl,
  privacyUrl,
  openInNewTab,
}: any) => {
  const linkProps = openInNewTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  return (
    <Wrapper>
      By {text}, I accept the{' '}
      <AnchorLink to={termsUrl} {...linkProps}>
        Terms and Conditions{' '}
      </AnchorLink>
      and{' '}
      <AnchorLink to={privacyUrl} {...linkProps}>
        Privacy Policy
      </AnchorLink>
      .
    </Wrapper>
  );
};

const Wrapper = styled.p`
  font-size: 12px;
  margin: 6px 8px 0px;
  color: #37393a;
`;

const AnchorLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  color: #302f2f;
`;

export default LegalLinksWrapper;

// By clicking on Login, I accept the Terms & Conditions & Privacy Policy
