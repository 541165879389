import { createGlobalStyle } from 'styled-components';
import { QUERIES } from '../../constants/constants';

const GlobalStyles = createGlobalStyle`
 /* CSS Variables */
 :root {
      --font-weight-light: 300;
      --font-weight-regular: 400;
      --font-weight-medium: 500;
      --font-weight-semi-bold: 600;
      --font-weight-bold: 700;

      --font-family: 'Inter', sans-serif, 'Wotfard', Futura, --apple-system, sans-serif;

      /* --elevation-high: importfromconstants; */

      /* --color-text: djfkljf;
      --color-text: dkjfkdjf;
      --color-background: dkfjkdhf;
      --color-blurred-background: dfkdhf; */
      --color-primary:hsl(150.31, 88.48%, 28.0%);
      /* --color-primary: #008056; */
      /* --color-primary: #098d13; */
      --color-secondary: hsl(150.31, 88.48%, 40.0%);
      --color-tertiary:  hsl(150.31, 88.48%, 45.0%);
      /* --color-decorative: dfjkdkljfss;
      --color-muted: kfjdklfjdk;
      --color-info: dkfjsdklj; */
      --color-error: red;
      --color-success: hsl(160deg 100% 35% / 1);
      --color-success-background: hsl(160deg 100% 40% / 0.1);
      --color-primary: hsl(0deg 100% 32.35%);
      --color-secondary: hsl(43.97deg 100% 48.43%);
      --color-primary-light: hsl(0deg 100% 98.35%);
      --color-secondary-light:  hsl(43.96, 100%, 98.43%);
      --color-cta-prev: hsl(146.67deg 55.29% 80%);
      --color-cta: hsl(43.97deg 100% 48.43%);


      /* --color-shadow-red: hsl(0deg 100% 98%);
      --color-shadow-yellow: hsl(43.96deg 100% 98%); */
      /* --color-warning: dkfjsldjf;
      --color-warning-background: dkfjslfjk;
      --color-gray-100: dkjfklsdjfkl;
      --color-gray-200: dkjfklsdjfkl
      --color-gray-300: dkjfklsdjfkl
      --color-gray-400: dkjfklsdjfkl
      --color-gray-500: dkjfklsdjfkl
      --color-gray-600: dkjfklsdjfkl
      --color-gray-700: dkjfklsdjfkl
      --color-gray-800: dkjfklsdjfkl
      --color-gray-900: dkjfklsdjfkl
      --color-gray-1000: dkjfklsdjfkl

      --syntax-bg: fkdjfklasjd
      --syntax-highlight: djfsjadhfjk
      --syntax-txt: dkfjksjdf
      --syntax-comment: dkjfksldjf
      --syntax-prop: dkfjsldjfk
      --syntax-bool: djfhdsfkdj
      --syntax-str: djfksdkffj
      --syntax-val: djflskdjkf
      --syntax-str: djflskdjkf
      --syntax-name: djflskdjkf
      --syntax-del: djflskdjkf
      --syntax-regex: djflskdjkf
      --syntax-fn: djflskdjkf

      --gradient-golden: dkjfkldsjfk */
    }



    /* Global Styles */
    *,
    *::before,
    *::after {
      box-sizing: border-box;
      line-height: 1.45;
      /* font-family: var(--font-family); */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      -webkit-text-size-adjust: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0;
      padding: 0;
      outline: none;
    }

    #root {
      /*
        Create a stacking context, without a z-indexx.
        This ensures that all portal content (modals and tooltips) will
        float above the React app.
      */
        height: 100%;
        isolation: isolate;
    }

    body {
      color: var(--color-text);
      background: var(--color-background);

    }

    html, body, #root {
      height: 100%;
      /* font-size: 62.5%; */
      /* font-family: var(--font-family) */
      scroll-behavior: smooth
    }

    /* html {
      height: 100%;
      scroll-behavior: smooth
    } */

    li, ul {
    list-style: none;
    margin: 0;
    padding: 0;
    }

    button {
      display: block;
      margin: 0px;
      padding: 0px;
      border: none;
      background: transparent;
      cursor: pointer;
      text-align: left;
      font: inherit;
      color: inherit;
    }

    a:focus {
      outline: 5px auto var(--color-primary)
    }

    body, input, button, select, option {
      font-family: var(--font-family);
      font-weight: var(--font-weight-light);
    }

    h1, h2, h3, h4, h5, h6, strong {
      font-weight: var(--font-weight-bold);
    }

    h1, h2, h3, h4, h5, h6, p {
      text-rendering: optimizeLegibility;
    }

    p {
      margin-bottom: 1.5em;
      font-size: 1.125rem;
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: var(--font-weight-medium);
    }

  :target {
    -webkit-animation: target-fade 2s 1;
    -moz-animation: target-fade 2s 1;
    margin: 0 -16px;
    padding: 0 16px;
  }

  @-webkit-keyframes target-fade {
    0% {
      background-color: hsl(120, 95%, 98%);
      /* color: white; */
    }
    100% {
      background-color: hsl(120, 95%, 98%);
      /* color: white; */
    }
  }

  @-moz-keyframes target-fade {
    0% {
      background-color: hsl(120, 95%, 98%);
      /* color: white; */
    }
    100% {
      background-color: hsl(120, 95%, 98%);
      /* color: white; */
    }
  }


  /* Scrollbar and selection styles */
  /* ::selection {
    background-color: steelblue;
    color: white;
  } */

  /* @media (orientation: landscape) {
    ::-webkit-scrollbar {
      width: 9px;
      height: 11px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: black;
      border: 2px solid black;
    }
  } */

  @media ${QUERIES.tabletAndUp}{
    html::-webkit-scrollbar, body::-webkit-scrollbar {
      width: 9px;
      height: 9px;
      background-color: transparent;
    }
  }

  @media ${QUERIES.tabletAndUp} {
    ::-webkit-scrollbar {
      width: 9px;
      height: 9px;
      background-color: transparent;
    }
  }

  @media ${QUERIES.tabletAndUp} {
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: hsl(210deg 10% 40% / .6);
      border: 2px solid var(#555, hsl(210deg 15% 6% / 1));
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #888888;
    }

  }
`;

export default GlobalStyles;
