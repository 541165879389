import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hook';
import { isTokenExpired } from '../../utils/tokenUtils';

const RequireAuth = ({ allowedRoles }: any) => {
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState(false);

  const { token, roles } = useAppSelector(
    (state) => state.auth.data.userInfo
  );

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else {
      const isExpiredToken = isTokenExpired(token);
      setIsExpired(isExpiredToken);
    }
  }, [navigate, token, isExpired]);

  return token &&
    !isExpired &&
    allowedRoles.find((myrole: any) =>
      roles.find((role: any) => role == myrole)
    ) ? (
    <Outlet />
  ) : token && !isExpired ? (
    <Navigate to="/denied" />
  ) : (
    <Navigate to="/login" />
  );
};

export default RequireAuth;
