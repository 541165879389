// 'use client';
import React from 'react';
import { useState, useEffect } from 'react';
import styled, {
  keyframes,
  createGlobalStyle,
} from 'styled-components';

interface NumberProps {
  $changed: boolean;
}

export default function CountdownTimer() {
  // Target date: April 7th at 10:00 AM
  const targetDate = new Date('April 7, 2025 10:00:00').getTime();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [changed, setChanged] = useState({
    days: false,
    hours: false,
    minutes: false,
    seconds: false,
  });

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setIsExpired(true);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Check which values have changed to trigger animations
      setChanged({
        days: days !== timeLeft.days,
        hours: hours !== timeLeft.hours,
        minutes: minutes !== timeLeft.minutes,
        seconds: seconds !== timeLeft.seconds,
      });

      setTimeLeft({ days, hours, minutes, seconds });

      // Reset the changed state after animation
      const resetTimeout = setTimeout(() => {
        setChanged({
          days: false,
          hours: false,
          minutes: false,
          seconds: false,
        });
      }, 500);

      return () => clearTimeout(resetTimeout);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft, targetDate]);

  // Generate random particles
  const particles = Array.from({ length: 20 }, (_, index) => ({
    id: index,
    size: Math.random() * 8 + 2,
    left: Math.random() * 100,
    duration: Math.random() * 10 + 5,
    delay: Math.random() * 5,
    translateX: Math.random() * 100 - 50, // Random translateX value
    rotate: Math.random() * 360, // Random rotation value
  }));

  return (
    <>
      <Container>
        <ParticlesContainer>
          {particles.map((particle) => (
            <Particle
              key={particle.id}
              size={particle.size}
              left={particle.left}
              duration={particle.duration}
              delay={particle.delay}
              translateX={particle.translateX} // Pass the value
              rotate={particle.rotate} // Pass the value
            />
          ))}
        </ParticlesContainer>

        <Title>Countdown to Our Big Launch!</Title>

        {isExpired ? (
          <LaunchMessage>
            Our event has started! Thank you for your patience and
            excitement.
          </LaunchMessage>
        ) : (
          <>
            <TimerContainer>
              <TimeUnit>
                <RectangleWrapper>
                  <Number $changed={changed.days}>
                    {timeLeft.days}
                  </Number>
                </RectangleWrapper>
                <Label>Days</Label>
              </TimeUnit>
              <DotsWrapper>
                <Dot></Dot>
                <Dot></Dot>
              </DotsWrapper>
              <TimeUnit>
                <RectangleWrapper>
                  <Number $changed={changed.hours}>
                    {timeLeft.hours.toString().padStart(2, '0')}
                  </Number>
                </RectangleWrapper>
                <Label>Hours</Label>
              </TimeUnit>
              <DotsWrapper>
                <Dot></Dot>
                <Dot></Dot>
              </DotsWrapper>
              <TimeUnit>
                <RectangleWrapper>
                  <Number $changed={changed.minutes}>
                    {timeLeft.minutes.toString().padStart(2, '0')}
                  </Number>
                </RectangleWrapper>
                <Label>Minutes</Label>
              </TimeUnit>
              <DotsWrapper>
                <Dot></Dot>
                <Dot></Dot>
              </DotsWrapper>
              <TimeUnit>
                <RectangleWrapper>
                  <Number $changed={changed.seconds}>
                    {timeLeft.seconds.toString().padStart(2, '0')}
                  </Number>
                </RectangleWrapper>
                <Label>Seconds</Label>
              </TimeUnit>
            </TimerContainer>

            <LaunchMessage>
              💥 Get ready to feast and save BIG! Unlock{' '}
              <BoldText>
                exclusive discounts 🚀 and irresistible promotions
              </BoldText>
              that will make every bite even sweeter.
            </LaunchMessage>
          </>
        )}
      </Container>
    </>
  );
}

// Animation for the background
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Animation for the countdown number change
const numberChange = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  20% {
    transform: translateY(-10px);
    opacity: 0;
  }
  80% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Animation for the particles
const floatParticle = (
  translateX: number,
  rotate: number
) => keyframes`
  0% {
    transform: translateY(0) translateX(${translateX}px) rotate(${rotate}deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px) translateX(${translateX}px) rotate(${rotate}deg);
    opacity: 0;
  }
`;

// const GlobalStyle = createGlobalStyle`
//   body {
//     margin: 0;
//     padding: 0;
//     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
//     overflow-x: hidden;
//   }
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  /* background: linear-gradient(-45deg, #a50000, #f7b500, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite; */
  /* margin-top: -3rem; */
  /* padding: 2rem; */
  padding-top: 48px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 800;
  color: #f7b500;
  text-align: center;
  margin-bottom: 3rem;
  display: inline-block;
  background-clip: text;
  background-image: linear-gradient(
    90deg,
    rgb(247, 181, 0),
    rgb(165, 0, 0)
  );
  color: transparent;
  /* text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const TimerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const TimeUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RectangleWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 10%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  /* border: 2px solid rgba(255, 255, 255, 0.3); */
  --shadow-color: 43.96deg 100% 48.43%;
  box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
    0px 0px 4px -2px hsl(var(--shadow-color));
  border: 1px solid transparent;

  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
  }
`;

const DotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* justify-content: center; */
  align-items: center;
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-top: 60px;
  }
`;

const Dot = styled.div`
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(0, 0, 0);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  /* border: 2px solid rgba(255, 255, 255, 0.3); */
  /* --shadow-color: 43.96deg 100% 48.43%;
  box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
    0px 0px 4px -2px hsl(var(--shadow-color));
  border: 1px solid transparent; */

  /* @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  } */
`;

const Number = styled.div<NumberProps>`
  font-size: 3rem;
  font-weight: 700;
  color: #a80000;
  animation: ${(props: any) =>
      props.$changed ? numberChange : 'none'}
    0.5s ease-in-out;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Label = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: #f7b500;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
`;

const ParticlesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const Particle = styled.div<{
  size: number;
  left: number;
  duration: number;
  delay: number;
  translateX: number;
  rotate: number;
}>`
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  left: ${(props) => props.left}%;
  animation: ${(props) =>
      floatParticle(props.translateX, props.rotate)}
    ${(props) => props.duration}s linear infinite;
  animation-delay: ${(props) => props.delay}s;
`;

const LaunchMessage = styled.div`
  font-size: 1.2rem;
  color: #a50000;
  /* font-weight: 700; */
  text-align: center;
  max-width: 600px;
  line-height: 1.6;
  margin-top: 2rem;
  backdrop-filter: blur(10px);
  padding: 1.5rem;

  border-radius: 10px;
  /* --shadow-color: 43.96deg 100% 48.43%;
  box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
    0px 0px 4px -2px hsl(var(--shadow-color)); */
  border: 1px solid #a50000;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 1rem;
    margin: 1.5rem;
  }
`;

const BoldText = styled.div`
  font-weight: 700;
`;
