import React, { useState, useEffect, useRef } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import TextInput from '../TextInput';
import PhoneInput from '../PhoneInput';
import { QUERIES } from '../../constants/constants';
import { otpVerifyApi, signupApi } from '../../services/auth-service';
import { setUserData } from '../../feature/auth/authSlice';
import { useAppDispatch } from '../../store/hook';
import WelcomeModal from '../WelcomeModal/WelcomeModal';
import LegalLinksWrapper from '../LegalLinksWrapper';
import Spacer from '../Spacer/Spacer';
import TextableDivider from '../TextableDivider';
import BottomModal from '../Modal/BottomModal';
import { AlertCircle, X } from 'react-feather';
import CountdownTimer from '../CountdownTimer';

const cookies = new Cookies();

let currentOtpIndex = 0;
const SignUp = ({ mobileNumber }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const [phoneNumber, setPhoneNumber] = useState(
    mobileNumber ? mobileNumber : ''
  );
  const [phoneValidationMessage, setPhoneValidationMessage] =
    useState('');
  const [name, setName] = useState('');
  const [nameValidationMessage, setNameValidationMessage] =
    useState('');
  const [email, setEmail] = useState('');
  const [emailValidationMessage, setEmailValidationMessage] =
    useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpValidationMessage, setOtpValidationMessage] =
    useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [setWelcomeModalOpen, isSetWelcomeModalOpen] =
    useState(false);

  const inputRef: any = useRef(null);

  console.log('email', email);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  const handleOnChange = (event: any, index: any) => {
    const value = event?.target.value;
    if (isNaN(value)) return false;

    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
    }

    setOtp(newOtp);
  };

  const handleOnKeyDown = (event: any, index: any) => {
    currentOtpIndex = index;

    if (event.key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowLeft') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowRight') {
      setActiveOtpIndex(currentOtpIndex + 1);
    }
  };

  const validatePhoneNumber = function () {
    const phoneRegex = new RegExp('^[6-9][0-9]{9}');
    if (!phoneRegex.test(phoneNumber) || phoneNumber === '') {
      setPhoneValidationMessage('Enter phone number');
    } else {
      setPhoneValidationMessage('');
    }
  };

  const validateEmail = function () {
    const emailRegex = new RegExp(
      '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'
    );
    if (!emailRegex.test(email) || email === '') {
      setEmailValidationMessage('Enter your email address');
    } else {
      setEmailValidationMessage('');
    }
  };

  const validateName = function () {
    if (!name) {
      setNameValidationMessage('Enter your name');
    } else {
      setNameValidationMessage('');
    }
  };

  const signup = async () => {
    const payload = {
      phoneNumber: phoneNumber,
      name: name,
      email: email.toLowerCase(),
    };

    try {
      const response = await signupApi(payload);
      if (response.data.success) {
        setShowOtp(true);
        setOtpSent(true);
        history.pushState(null, '', '/otp-verify');
      }
    } catch (error: any) {
      console.log('Error', error);

      setErrorMessage(
        error.code === 'ERR_NETWORK'
          ? 'Something went wrong'
          : error?.response?.data?.message
      );
      setShowOtp(false);
      if (!error?.response?.data?.success) {
        navigate('/signup');
      }
    }
  };

  const otpVerify = async () => {
    const payload = {
      phoneNumber: phoneNumber,
      otp: otp.join(''),
    };

    try {
      const response = await otpVerifyApi(payload);
      if (response.data.success) {
        setShowOtp(false);
        history.pushState(null, '', '/welcome');

        console.log(
          '🚀 ~ file: SignUp.tsx:133 ~ otpVerify ~ response.data:',
          response.data.name
        );
        cookies.set('token', response?.data?.data.token, {
          path: '/',
          expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          // httpOnly: true,
          // secure: true,
        });

        dispatch(setUserData(response.data.data));

        isSetWelcomeModalOpen(true);
        // navigate('/');
      }
    } catch (error: any) {
      setOtpValidationMessage(
        error.code === 'ERR_NETWORK'
          ? 'Something went wrong'
          : error?.response?.data?.message
      );
      setShowOtp(false);
    }
  };

  const handleSendOtp = (event: any) => {
    event.preventDefault();
    validatePhoneNumber();
    validateEmail();
    validateName();

    if (
      !phoneValidationMessage &&
      !nameValidationMessage &&
      !emailValidationMessage &&
      phoneNumber &&
      name &&
      email
    ) {
      signup();
    }
  };

  const handleResendOTP = () => {
    validatePhoneNumber();

    if (!phoneValidationMessage && phoneNumber) {
      console.log('user is signed in');
      signup();
    }
  };

  const handleVerifyOtp = (event: any) => {
    event.preventDefault();
    otpVerify();
  };

  const handleTimeOver = () => {
    setOtpSent(false);
  };

  return (
    <SignupWrapper>
      <LoginText>Sign up</LoginText>
      <SubTitle>Create your account</SubTitle>

      <AuthForm>
        <PhoneInput
          errorMsg={phoneValidationMessage}
          required={true}
          label={
            phoneValidationMessage
              ? phoneValidationMessage
              : 'Phone number'
          }
          type="tel"
          value={phoneNumber}
          onChange={(event: any) => {
            if (isNaN(event.target.value)) return false;
            setPhoneValidationMessage('');
            setPhoneNumber(event.target.value);
          }}
          onBlur={() => {
            if (phoneNumber) {
              validatePhoneNumber();
            }
          }}
          autoComplete="on"
          autoFocus={true}
          maxLength={10}
        />

        <TextInput
          errorMsg={nameValidationMessage}
          required={true}
          label={nameValidationMessage ? 'Enter your name' : 'Name'}
          type="text"
          value={name}
          onBlur={() => {
            if (name) {
              validateName();
            }
          }}
          onChange={(event: any) => {
            setNameValidationMessage('');
            setName(event.target.value);
          }}
          autoComplete="off"
          maxLength={30}
        />

        <TextInput
          errorMsg={emailValidationMessage}
          required={true}
          label={
            emailValidationMessage ? emailValidationMessage : 'Email'
          }
          type="email"
          value={email.toLowerCase()}
          onBlur={() => {
            if (email) {
              validateEmail();
            }
          }}
          onChange={(event: any) => {
            setEmailValidationMessage('');
            setEmail(event.target.value.toLowerCase());
          }}
          autoComplete="off"
          maxLength={100}
        />

        {showOtp && (
          <BottomModal
            // handleDismiss={() => setShowOtp(false)}
            maxWidth="440px"
          >
            <Header>
              <HeadingText>OTP Verification</HeadingText>
              <CancelButton
                onClick={() => {
                  setShowOtp(false);
                }}
              >
                <X />
              </CancelButton>
            </Header>
            <OTPVerifyWrapper>
              <Content>
                <OTPText>Check text messages for your OTP</OTPText>

                <OtpWrapper>
                  {/* // TODO : style Error message  */}

                  <OtpInnerWrapper>
                    <InputsWrapper>
                      {otp.map((_: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              ref={
                                index === activeOtpIndex
                                  ? inputRef
                                  : null
                              }
                              autoFocus={
                                index === activeOtpIndex
                                  ? inputRef
                                  : null
                              }
                              type="number"
                              inputMode="numeric"
                              maxLength={1}
                              pattern="[a-zA-Z0-9]{1}"
                              value={otp[index]}
                              onChange={(event) =>
                                handleOnChange(event, index)
                              }
                              onKeyDown={(event) =>
                                handleOnKeyDown(event, index)
                              }
                              onFocus={(e) => e.target.select()}
                            />
                          </React.Fragment>
                        );
                      })}
                    </InputsWrapper>
                  </OtpInnerWrapper>
                </OtpWrapper>
              </Content>

              {errorMessage && (
                <>
                  <OTPErrorMessageWrapper>
                    <ErrorIcon>
                      <AlertCircle size={18} color="#d40a0a" />
                    </ErrorIcon>
                    <Text>{errorMessage}</Text>
                  </OTPErrorMessageWrapper>
                </>
              )}

              <ButtonsWrapper>
                <CtaButton
                  type="submit"
                  onClick={(e) => {
                    handleVerifyOtp(e);
                  }}
                >
                  VERIFY OTP
                </CtaButton>
              </ButtonsWrapper>
              <CountdownTimer
                initialTime={60}
                onTimeOver={handleTimeOver}
                handleAction={handleResendOTP}
              />
              {/* // TODO : show Error message */}
              {/* TODO: dispatch an action to show location modal */}
            </OTPVerifyWrapper>
          </BottomModal>
        )}

        {errorMessage && (
          <>
            <ErrorMessageWrapper>
              <ErrorIcon>
                <AlertCircle size={18} color="#d40a0a" />
              </ErrorIcon>
              <Text>{errorMessage}</Text>
            </ErrorMessageWrapper>
          </>
        )}

        <SubmitButton onClick={handleSendOtp}>CONTINUE</SubmitButton>

        {/* TODO: create a component LegalLinksWrapper instead of div */}
        <LegalLinksWrapper
          text="creating an account"
          termsUrl="https://ymiieat.com/terms"
          privacyUrl="https://ymiieat.com/privacy"
          openInNewTab={true}
        />

        <Spacer y={20} />

        <TextableDivider text="or" margin="0 10px" />

        <LoginLinkWrapper>
          <LoginLink to="/login">LOGIN</LoginLink>
        </LoginLinkWrapper>

        <Spacer y={32} />
      </AuthForm>

      {setWelcomeModalOpen && (
        <WelcomeModal setIsOpen={isSetWelcomeModalOpen} />
      )}
    </SignupWrapper>
  );
};

const SignupWrapper = styled.div``;

const LoginText = styled.h4`
  font-size: 20px;
  margin: 2px 0 8px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  margin: 0%;
  color: #707579;
  line-height: 1.3125;
  text-align: center;
`;

const ErrorMessage = styled(SubTitle)`
  text-align: start;
  margin-top: 16px;
  margin-left: 20px;
  color: #d40000;
  font-weight: 600;
  font-size: 16px;
`;

const AuthForm = styled.form`
  /* flex: 0; */
  height: 100%;
  width: 100%;
  margin-top: 40px;
  padding: 0px 16px;

  @media ${QUERIES.tabletAndUp} {
    padding: 0;
  }
`;

const OtpWrapper = styled.div`
  text-align: center;
  font-size: 20px;
  margin-top: 16px;
  padding: 7px 6px;
`;

const OtpInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 370px;
`;

const Input = styled.input`
  height: 48px;
  /* width: 48px; */
  width: 15%;
  padding: 0px;
  text-align: center;
  font-size: 19px;
  font-weight: 700;
  color: rgb(28, 28, 28);
  border: 2px solid rgb(207, 207, 207);
  border-radius: 14px;
  appearance: none;
  outline: none;
  appearance: none;
  outline: none;
  transition: all 250ms;
  border: ${(prop: any) => prop.value && '2px solid #161616'};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    border: 2px solid #077507;
  }
`;

const SubmitButton = styled.button`
  margin-top: 24px;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  text-align: center;
  color: black;
  background-color: var(--color-cta);
  letter-spacing: 0.3px;
`;

const LoginLinkWrapper = styled.div`
  margin-top: 24px;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  text-align: center;
  color: black;
  background-color: #38444d;
  /* --shadow-color: 145deg 100% 12%;
box-shadow: 0px 0px 4px -2px hsl(var(--shadow-color)),
  0px 0px 4px -2px hsl(var(--shadow-color)); */
`;

const LoginLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: white;
  width: 100%;
  height: 100%;
  letter-spacing: 0.3px;

  &:hover {
    color: #cfcfcf;
  }
`;

const OTPText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 16px;
  color: #413e3e;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;

  @media ${QUERIES.tabletAndUp} {
    font-size: 15px;
    gap: 20px;
  }
`;

const ModalButton = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid var(--color-secondary);
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 2px -2px hsl(var(--shadow-color));

  &:hover {
    box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
      0px 0px 4px -2px hsl(var(--shadow-color));
  }

  @media ${QUERIES.tabletAndUp} {
    height: 50px;
  }
`;

const CtaButton = styled(ModalButton)`
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: black;
  background-color: var(--color-cta);

  --shadow-color: 0deg 100% 38.35%;
  box-shadow: 0px 2px 2px -2px hsl(var(--shadow-color));

  &:hover {
    box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
      0px 0px 4px -2px hsl(var(--shadow-color));
  }
`;

const CancelButton = styled(ModalButton)`
  width: fit-content;
  height: fit-content;
`;

const OTPVerifyWrapper = styled.div`
  margin: 0 auto;
  max-width: 370px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* max-width: 370px; */
`;

const Content = styled.div`
  margin-top: 32px;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 24px;
  border-radius: 12px;
`;
const HeadingText = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 6px;
  padding-left: 4px;
  letter-spacing: 0.3px;

  @media ${QUERIES.tabletAndUp} {
    font-size: 22px;
  }
`;

const ErrorIcon = styled.div`
  margin-right: 6px;
  width: 18px;
  height: 18px;
`;

const Text = styled.div``;

const ErrorMessageWrapper = styled(SubTitle)`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 16px;
  margin-left: 20px;
  color: #d40a0a;
  font-size: 16px;
  font-weight: 600;
`;

const OTPErrorMessageWrapper = styled(ErrorMessageWrapper)`
  margin-top: -16px;
`;

export default SignUp;
