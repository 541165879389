import React, { Children } from 'react';
import styled from 'styled-components';
import { QUERIES } from '../../constants/constants';

function TextInput({ id, label, ...delegated }: any) {
  const generatedId = React.useId();
  const appliedId = id || generatedId;

  const LabelStyles = {
    transform:
      delegated.value || delegated.defaultValue
        ? 'scale(0.8) translateY(-75%)'
        : '',
    color: delegated.errorMsg ? 'var(--color-primary)' : '',
  };

  return (
    <InputWrapper>
      <Input id={appliedId} {...delegated} />
      <Label htmlFor={appliedId} style={LabelStyles}>
        {label}
      </Label>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0%;
  margin-top: 16px;
  border-radius: 14px;
  overflow: hidden;
`;

const Label = styled.label`
  position: absolute;
  bottom: 16px;
  left: 0px;
  padding-left: 26px;
  color: hsl(0, 3%, 45%);
  background-color: transparent;
  font-weight: 400;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
  z-index: 1;
  transform-origin: bottom left;
  font-size: 17px;
  font-weight: 500;
  pointer-events: none;
  will-change: transform, color, font-size;
`;

const Input = styled.input`
  /* border-radius: 4px; */
  color: #29292b;
  font-size: 17px;
  font-weight: 700;
  height: 60px;
  padding: 24px 20px 0;
  display: block;
  width: 100%;
  background-color: transparent;
  outline: none;
  appearance: none;
  background-color: hsl(0, 0%, 95.3%);
  border: 2px solid
    ${(props: any) =>
      props.errorMsg ? 'var(--color-primary)' : 'transparent'};
  border-radius: 14px;

  /* focus pseudo classes styles */

  &:focus + ${Label} {
    transform: scale(0.8) translateY(-75%);
    width: max-content;
    color: #077507;
  }

  &:focus,
  ${InputWrapper} {
    border: 2px solid
      ${(props: any) =>
        props.errorMsg ? 'var(--color-primary)' : 'green'};
    border-radius: 14px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:disabled,
  &:read-only {
    overflow-x: auto;
    white-space: nowrap;
    text-overflow: ellipsis;

    /* width: 100%; */
    /* max-width: 550px; */
  }

  &:active {
    outline: black;
  }
`;

export default TextInput;
