import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Portal from '../Portal/Portal';
import { X } from 'react-feather';
import { QUERIES } from '../../constants/constants';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import Spacer from '../Spacer/Spacer';
import ReactFocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { useEscapeKey } from '../../hooks/useEscapeKey';

const BottomModal = ({
  heading,
  children,
  handleDismiss,
  hideCloseButtonOnMobile,
  height,
  showCloseButton,
  padding = true,
  maxWidth = '600px',
}: any) => {
  useEscapeKey(handleDismiss);

  const closeButtonStyles = {
    '--hide': hideCloseButtonOnMobile ? 'none' : 'block',
  };

  return (
    <Portal>
      <ReactFocusLock returnFocus={true}>
        <RemoveScroll>
          <ModalWrapper>
            <BackgroundOverlay
              onClick={handleDismiss}
            ></BackgroundOverlay>
            <ModalWrapperInner
              aria-modal="true"
              role="dialog"
              // tabindex="-1"
              aria-label="Search your location"
              // data-state='open'
              // style="--max-modal-width:800px;"
              height={height}
              maxWidth={maxWidth}
            >
              {!heading && showCloseButton && (
                <CloseButton
                  onClick={handleDismiss}
                  style={closeButtonStyles as any}
                  // style="--x-color:black;"
                >
                  <X size={32} stroke="currentColor" />
                  <VisuallyHidden>Dismiss Modal</VisuallyHidden>
                </CloseButton>
              )}

              {heading && (
                <HeaderWrapper>
                  <Heading>{heading}</Heading>
                  <CloseButton2
                    onClick={handleDismiss}
                    style={closeButtonStyles as any}
                    // style="--x-color:black;"
                  >
                    <X size={24} stroke="currentColor" />
                    <VisuallyHidden>Dismiss Modal</VisuallyHidden>
                  </CloseButton2>
                </HeaderWrapper>
              )}

              {/* //////////////////////////////////////////////////////// */}

              <ModalContentWrapper padding={padding}>
                <ModalContent
                // data-color-mode="light"
                >
                  <ModalContentAnimation>
                    {children}
                  </ModalContentAnimation>
                </ModalContent>
              </ModalContentWrapper>
            </ModalWrapperInner>
          </ModalWrapper>
        </RemoveScroll>
      </ReactFocusLock>
    </Portal>
  );
};

const ModalWrapper = styled.div`
  background: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  isolation: isolate;
  /* position: fixed;
  inset: 0px;
  background: transparent;
  isolation: isolate;
  overflow: hidden;
  z-index: 9999; */
`;

const BlurKeyframe = keyframes`
  0% {
    opacity: 0;
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: auto;
  }
`;

const BackgroundOverlay = styled.div`
  --color-blurred-background: hsl(210deg 15% 6% / 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-blurred-background);
  /* backdrop-filter: blur(15px); */
  animation: 1000ms ease 0s 1 normal none running ${BlurKeyframe};
  overflow: hidden;
  z-index: 10;
`;

const ContentKeyframe1 = keyframes`
  0% {
      transform: translate(-50%, 0%);
  }

  100% {
      transform: translate(-50%, calc(-50% - 80px));
  }
`;

const ContentKeyframe2 = keyframes`
 0% {
    opacity: 0;
    /* pointer-events: none; */
  }
100% {
    opacity: 1;
    /* pointer-events: auto; */
  }
`;

const ContentKeyframe3 = keyframes`
  0%{
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const ModalWrapperInner = styled.div<any>`
  /* overflow-y: auto; */

  @media (max-width: 550px) {
    position: relative;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* height: ${(props) => props.height + 'vh'}; */
    padding: 0px;
    margin: 0px;
    animation: 500ms cubic-bezier(0.1, 0.8, 0.2, 1) 0ms 1 normal both
      running ${ContentKeyframe3};
    border-radius: 14px 14px 0px 0px;
    background-color: white;
    /* backface-visibility: hidden; */
  }

  @media (${QUERIES.tabletAndUp}) {
    background-color: white;
    position: relative;
    width: ${(props: any) =>
      props.maxWidth ? props.maxWidth : '600px'};
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    bottom: 0;
    top: 50%;
    /* transform: translateX(-50%); */
    transform: translate(-50%, -50%);
    border-radius: 10px 10px 10px 10px;
    animation: 500ms cubic-bezier(0.1, 0.8, 0.2, 1) 0ms 1 normal both
      running ${ContentKeyframe1};
    z-index: 200;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-bottom: 0px;
    padding: 0px;
    will-change: transform;
    --shadow-elevation-high: 0px 0.2px 0.3px
        hsl(var(--shadow-color) / 0.06),
      0px 2.3px 3px -0.2px hsl(var(--shadow-color) / 0.08),
      0.1px 4.3px 5.6px -0.4px hsl(var(--shadow-color) / 0.09),
      0.1px 6.6px 8.7px -0.5px hsl(var(--shadow-color) / 0.11),
      0.1px 9.8px 12.9px -0.7px hsl(var(--shadow-color) / 0.13),
      0.2px 14.3px 18.8px -0.9px hsl(var(--shadow-color) / 0.14),
      0.3px 20.8px 27.3px -1.1px hsl(var(--shadow-color) / 0.16),
      0.4px 29.6px 38.9px -1.2px hsl(var(--shadow-color) / 0.17);
    /* box-shadow: var(--shadow-elevation-high); */
  }
`;

const CloseButton = styled.div`
  position: absolute;
  z-index: 300;
  top: 0px;
  right: 0px;
  transform: translate(-10px, -100%);
  /* transform: translateY(-50%); */
  display: var(--hide);
  padding: 16px;
  animation: 200ms ease 200ms 1 normal both running
    ${ContentKeyframe2};
  will-change: transform;
  color: #ffffff;
  cursor: pointer;

  @media (${QUERIES.tabletAndUp}) {
    display: revert;
    /* transform: translate(-8px, calc(-100% + 32px)); */
    transform: translate(-10px, -100%);
    animation: 350ms ease 450ms 1 normal both running
      ${ContentKeyframe2};
    color: white;
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0px 2px 3px);
  }

  & > svg {
    display: block;
  }
`;

const CloseButton2 = styled.button`
  /* padding: 10px; */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContentWrapper = styled.div<any>`
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 35.1875rem) {
    height: 100%;
    max-height: 100vh;
    border-radius: 0px;
  }

  flex: 1 1 0%;
  position: relative;
  padding: ${(props) => props.padding && 16 + 'px'};
`;

const ModalContent = styled.div`
  height: 100%;
  max-height: 100vh;
  border-radius: 0px;
  background-color: white;

  /* overflow-y: auto; */

  @media (${QUERIES.tabletAndUp}) {
    position: relative;
    max-height: 75vh;
    border-radius: 10px;
  }
`;

const ModalContentAnimation = styled.div`
  animation: 350ms ease 250ms 1 normal both running
    ${ContentKeyframe2};
  height: 100%;
  /* min-height: 100%; */

  /* min-height: calc(100% + 74px); */
`;

// TODO: update header color using props
const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  /* background-color: white; */
  /* TODO: add box shadow here */
  /* height: 60px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid hsl(225deg 16% 86% / 1);
  padding: 16px;
  animation: 350ms ease 250ms 1 normal both running
    ${ContentKeyframe2};

  @media (max-width: 550px) {
    padding: 20px;
  }
`;

const SpaceWrapperMobile = styled.div`
  display: block;

  @media (${QUERIES.tabletAndUp}) {
    display: none;
  }
`;

const SpaceWrapperDesktop = styled.div`
  display: none;

  @media (${QUERIES.tabletAndUp}) {
    display: block;
  }
`;

const Heading = styled.h2`
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  /* line-height: 1.5rem; */
`;

const ChildrenContent = styled.div`
  padding: 0px 16px 16px;

  @media (${QUERIES.tabletAndUp}) {
    padding: 0px 32px 32px;
  }
`;

export default BottomModal;
