import React from 'react';
import styled from 'styled-components';
import { QUERIES } from '../../constants/constants';

const Checkout = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <CheckoutStepsWrapper>
          <StepWrapper>
            <HeaderWrapper>
              <Heading>Delivery Address</Heading>
            </HeaderWrapper>
          </StepWrapper>

          <StepWrapper>
            <HeaderWrapper>
              <Heading>Payment Options</Heading>
            </HeaderWrapper>
          </StepWrapper>

          <ButtonWrapper>
            <Button>Place Order</Button>
          </ButtonWrapper>
        </CheckoutStepsWrapper>
        <CartWrapper>Cart</CartWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: hsl(0, 0%, 94.11764705882352%);
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  margin-bottom: 40px;
  position: relative;
`;

const CheckoutStepsWrapper = styled.div`
  margin-right: 30px;
  flex: 1;
`;

const StepWrapper = styled.div`
  padding: 32px;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: white;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 32px;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  /* margin-right: 30px; */
`;

const Button = styled.button`
  background-color: #c7c7c7;
  width: calc(100% - 20px);
  height: 42px;
  border-radius: 10px;
  padding: 10px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 16px;
  font-weight: var(--font-weight-semi-bold);
`;

const CartWrapper = styled.div`
  width: 370px;
  background-color: white;
`;

export default Checkout;
