import React, { useEffect, useState } from 'react';
import Footer from './components/Footer/Footer';
import styled, { keyframes } from 'styled-components';
import { Header } from './components/Header';
import MobileBottomNav from './components/MobileBottomNav/MobileBottomNav';
import { QUERIES } from './constants/constants';
import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import { useAppDispatch, useAppSelector } from './store/hook';
// import Portal from './components/Portal/Portal';
// import ToastContainer from './feature/toaster/ToastContainer';
// import { createToast } from './feature/toaster/ToastSlice';
// import Socket from './components/Socket/Socket';
// import Modal from './components/Modal/Modal';
// import SearchLocation from './feature/SearchLocation/SearchLocation';
// import BottomModal from './components/Modal/BottomModal';
// import NotificationPopover from './components/LocationErrorPopover/LocationErrorPopover';
// import { X } from 'react-feather';
import LocationErrorPopover from './components/LocationErrorPopover/LocationErrorPopover';
// import Restaurant from './components/Restaurant/Restaurant copy';
import Restaurant from './components/Restaurant/Restaurant';
// import AddressForm from './components/AddressForm/AddressForm';
// import NewMap from './components/NewMap/NewMap';
// import NewSocket from './components/Socket/NewSocket';
// import DeliveryPage from './feature/delivery/DeliveryPage';
// import OrdersPage from './feature/order/OrdersPage';
import Logo from './assets/logo.svg';

const cartData = {
  addressId: '',
  cartItems: {},
  restaurantId: '',
};
localStorage.setItem('cartData', JSON.stringify(cartData));

function App() {
  const { pathname } = useLocation();
  console.log('🚀 ~ file: App.tsx:37 ~ App ~ pathname:', pathname);
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { data } = useAppSelector((state) => state.toasts);
  const { locationError } = useAppSelector(
    (state) => state.searchLocation
  );

  useEffect(() => {
    history.pushState({ urlPath: '/' }, '', '/');
  }, []);

  // useEffect(() => {}, []);
  // const addToast = () => {
  //   dispatch(
  //     createToast({
  //       message: 'Toast Added. maximum  visible toasts will be 10.',
  //       variant: 'success',
  //     })
  //   );
  // };

  return (
    <RootLayout>
      {/* Uncomment on launch day!! */}
      {/* {pathname !== '/cart' && <Header />} */}
      <ImgaeWrapper>
        <Image src={Logo} alt="" width={160} height={100} />
      </ImgaeWrapper>
      <MainContent>
        <HeaderWrapper>
          <WelcomeText>Welcome</WelcomeText>

          <ToText>to</ToText>
          <Name>
            <YmiiEatText>YmiiEat</YmiiEatText>!
          </Name>
        </HeaderWrapper>
        {/* <NewSocket /> */}
        {/* <DeliveryPage /> */}
        {/* <button style={{ paddingTop: '100px' }} onClick={addToast}>
          add Toast
        </button> */}
        {/* <Carousel>Main Content</Carousel> */}
        {/* <button
          style={{
            padding: '2rem',
            background: 'black',
            color: 'white',
          }}
          onClick={() => setIsModalOpen(true)}
        >
          Toggle Bottom Modal [ Add height prop in Bottom Modal.]
        </button>

        {isModalOpen && (
          <BottomModal
            // heading="This is Header"
            handleDismiss={() => setIsModalOpen(false)}
          >
            Hello Amit
          </BottomModal>
        )} */}
        {/* <Socket /> */}
        <Outlet />
      </MainContent>
      {/* <Footer /> */}
      {/* {pathname !== '/cart' && <MobileBottomNav />} */}

      {locationError && (
        <LocationErrorPopover icon={<h5>YmiiEat</h5>}>
          {locationError}
        </LocationErrorPopover>
      )}
    </RootLayout>
  );
}

// ////////////////////////////////////////////////////

const RootLayout = styled.div`
  padding-top: 60px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

  @media ${QUERIES.laptopAndUp} {
    padding-top: 80px;
  }
`;

const MainContent = styled.main`
  flex-grow: 1;
  width: 100%;
  margin-top: 64px;

  /* TODO: delete this later */
  /* border: 1px solid black; */

  /* margin-top: 20px; */

  /* TODO: remove this later */
  @media ${QUERIES.tabletAndUp} {
    /* max-width: 1200px; */
    margin: 0 auto;
    margin-top: 10px;
  }
`;

const Carousel = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
`;

const TabList = styled.ul`
  display: flex;
  flex-direction: column;
  max-width: 10rem;
  min-width: 10rem;
  margin-right: 2rem;
`;

const HeaderWrapper = styled.div`
  /* width: 100%; */
  margin-bottom: 3rem;

  @media ${QUERIES.tabletAndUp} {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin: 0 auto;
  }
`;

const Text = styled.div`
  margin: 0px auto;
  font-size: 40px;
  font-family: 'Caveat', cursive;
  font-weight: 900;
  color: #a50000;
  text-align: center;

  @media ${QUERIES.tabletAndUp} {
    font-size: 48px;
  }
`;

const keyFrameLeftToRight = keyframes`
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  100% {
      clip-path: polygon(0% 0%, 105% 0%, 105% 100%, 0% 100%);
  }
`;

const WelcomeText = styled(Text)`
  /* width: fit-content; */
  /* font-size: 62px; */
  /* transform: translateX(-20px) rotate(0deg); */
  animation: 1000ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 700ms 1
    normal both running ${keyFrameLeftToRight};

  @media (max-width: 550px) {
    font-size: 40px;
  }
`;
const ToText = styled(Text)`
  /* transform: translateX(-20px) rotate(0deg); */
  animation: 400ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 1500ms 1
    normal both running ${keyFrameLeftToRight};
`;

const YmiiEatText = styled.div`
  display: inline-block;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #f7b500, #a50000);
  color: transparent;
  padding-right: 6px;
`;

const Name = styled(Text)`
  animation: 1200ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 1700ms 1
    normal both running ${keyFrameLeftToRight};
`;

const ImgaeWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  z-index: 999999;
  animation: 1000ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 100ms 1
    normal both running ${keyFrameLeftToRight};

  /* @media (max-width: 550px) {
    bottom: 30%;
  } */

  @media ${QUERIES.tabletAndUp} {
    padding-top: 80px;
    margin-bottom: 60px;
  }
`;

const Image = styled.img`
  display: block;
  height: 100%;
  max-width: 100%;
  z-index: 999999;
`;

export default App;

{
  /* <div
        style={{
          borderTop: '2px solid black',
          paddingTop: '80px',
        }}
      >
        <Tabs initialActiveTab="account">
          <div
            style={{
              display: 'flex',
              marginTop: '2rem',
              border: '1px solid var(--color-primary)',
              padding: '20px',
            }}
          >
            <TabList>
              <Tab id="account">Account</Tab>
              <Tab id="settings">Settings</Tab>
              <Tab id="privacy">Privacy</Tab>
              <Tab id="home">Home</Tab>
              <Tab id="faq">FAQ</Tab>
            </TabList>
            <div style={{ padding: '10px' }}>
              <TabPanel id="account">Account Panel</TabPanel>
              <TabPanel id="settings">Settings Panel</TabPanel>
              <TabPanel id="privacy">Privacy Panel</TabPanel>
              <TabPanel id="home">Home Panel</TabPanel>
              <TabPanel id="faq">FAQ Panel</TabPanel>
            </div>
          </div>
        </Tabs>
      </div> */
}
{
  /* <header style={{ margin: '1rem' }}>
        <NavLink
          style={({ isActive, isPending }) => {
            return {
              color: isActive ? 'red' : 'inherit',
            };
          }}
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          style={({ isActive, isPending }) => {
            return {
              color: isActive ? 'red' : 'inherit',
            };
          }}
          to="/about"
        >
          About
        </NavLink>
        <NavLink
          style={({ isActive, isPending }) => {
            return {
              color: isActive ? 'red' : 'inherit',
            };
          }}
          to="/login"
        >
          Login
        </NavLink>
        <NavLink
          style={({ isActive, isPending }) => {
            return {
              color: isActive ? 'green' : 'inherit',
            };
          }}
          to="/signup"
        >
          Signup
        </NavLink>
      </header>
      <Footer />
    */
}
